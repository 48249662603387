
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SignMobile extends Vue {
  handleSign() {
    this.$emit('onSign');
  }

  handleCancel() {
    this.$emit('onCancel');
  }
}
