
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Components } from '@/router/routes';

@Component({})
export default class NetworkManagementButton extends Vue {
  @Prop(Boolean) isGroupIcon!: boolean;
  @Prop(String) icon!: string;
  @Prop(String) selectedNetwork!: string;

  get isAssetPage() {
    const route = this.$route.name;

    return route === Components.AssetNetworks || route === Components.AssetHistory;
  }

  get assetPageWrapperClasses() {
    if (this.isAssetPage) return 'network-management--cursor-not-allowed';

    return '';
  }

  get assetPageClasses() {
    if (this.isAssetPage) return 'network-management--disabled';

    return '';
  }

  onToggle() {
    this.$emit('onToggle');
  }
}
