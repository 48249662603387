import axios from 'axios';
import { chrome } from '@extension-base/utils/crossenv';

import { type GoogleFileId } from '../../background/types/types';
import type { KeyringPair$Json } from '@subwallet/keyring/types';
import type {
  FilesResponse,
  GoogleAuthTypes,
  ICreateFile,
  IGetFilesResponse,
  RequestGoogleToken,
  VerifyTokenResponse,
} from '@/interfaces';
import { FEARLESS_TITLE } from '@/consts/global';

export class GoogleService {
  private readonly baseURL = 'https://www.googleapis.com/drive/v3';
  private readonly baseUploadUrl = 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart';
  private readonly extensionRedirectURL = chrome?.identity?.getRedirectURL('welcome');
  private readonly baseAuthParams = {
    client_id: process.env.OAUTH_CLIENT_ID,
    response_type: 'token',
    state: 'pass-through value',
    access_type: 'online',
    prompt: 'consent',
    scope: 'https://www.googleapis.com/auth/drive.appdata',
  };

  urlTypes = {
    main: 'google',
    export: 'fearless/wallet',
  };

  public get config() {
    return {
      params: {
        fields: 'files(id,name,description)',
        spaces: 'appDataFolder',
      },
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
      },
    };
  }

  private authURL(type: 'desktop' | 'extension') {
    const prepUrl = new URL(`https://accounts.google.com/o/oauth2/v2/auth`);
    const params: Record<string, string> = {
      ...this.baseAuthParams,
      redirect_uri: type === 'extension' ? this.extensionRedirectURL : `http://localhost:5500/${type}`,
    };

    Object.keys(params).forEach((key) => {
      prepUrl.searchParams.set(key, params[key]);
    });

    return prepUrl.href;
  }

  private prepareData(json: string, { name, address }: { name: string; address: string }) {
    return `--foo_bar_baz
Content-Type: application/json; charset=UTF-8

{
name: "${name}.json",
mimeType: "application/json",
description: "${address}",
parents: ["appDataFolder"]
}

--foo_bar_baz
Content-Type: application/json

${json}
--foo_bar_baz--`;
  }

  public async authExtension({ type = 'main', wallet }: GoogleAuthTypes) {
    return new Promise<void>((res) => {
      chrome.identity.launchWebAuthFlow(
        {
          url: this.authURL('extension'),
          interactive: true,
        },
        async (redirect_url) => {
          res();

          const searchParams = new URLSearchParams(redirect_url);
          const token =
            searchParams.get('access_token') || searchParams.get(`${this.extensionRedirectURL}#access_token`);
          const baseURL = `${chrome.runtime.getURL(`popup.html#/${this.urlTypes[type]}/${token}`)}`;
          const url = type === 'export' && wallet ? `${baseURL}?wallet=${wallet}` : baseURL;
          const tabs = await chrome.tabs.query({});

          const [openTab] = tabs.filter((el) => el.title?.includes(FEARLESS_TITLE));

          if (openTab && openTab.id) {
            chrome.tabs.update(openTab.id, { active: true, url });

            return;
          }

          chrome.tabs.create({ active: true, url });
        }
      );
    });
  }

  public async getFiles({ token }: RequestGoogleToken): Promise<IGetFilesResponse> {
    const { data } = await axios.get<IGetFilesResponse>(
      `${this.baseURL}/files?fields=files(id,name,description)&spaces=appDataFolder`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ...this.config.headers,
        },
      }
    );

    return data;
  }

  public async getFile({ id, token }: GoogleFileId): Promise<KeyringPair$Json> {
    const { data } = await axios.get<KeyringPair$Json>(`${this.baseURL}/files/${id}?alt=media`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...this.config.headers,
      },
    });

    return data;
  }

  public async verifyToken({ token }: RequestGoogleToken): Promise<VerifyTokenResponse | null> {
    const res = await axios
      .get<VerifyTokenResponse>(`https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${token}`)
      .catch(() => {
        return null;
      });

    if (res && res.data) return res.data;

    return null;
  }

  async createFile({ json, options, token }: ICreateFile): Promise<FilesResponse> {
    const prepData = this.prepareData(json, options);
    const length = prepData.length;

    const { data } = await axios.post<FilesResponse>(this.baseUploadUrl, prepData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/related; boundary=foo_bar_baz',
        'Content-Length': length.toString(),
      },
    });

    return data;
  }

  async deleteFile({ id, token }: GoogleFileId) {
    axios.delete(this.baseURL, {
      params: {
        fields: id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        ...this.config.headers,
      },
    });
  }
}
