
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Components } from '@/router/routes';
import { forgetAccount, initGoogleAuth, updateCurrentAccount } from '@/extension/messaging';
import { useAccountsStore } from '@/stores/accounts';
import { IS_EXTENSION } from '@/consts/global';

@Component
export default class WalletDetailsPopup extends Vue {
  readonly isExtension = IS_EXTENSION;
  accountsStore = useAccountsStore();

  @Prop(Number) buttonTopClick!: number;
  @Prop(String) selectedWalletAddress!: string;

  get selectedWallet() {
    return this.accountsStore.accounts.find((account) => account.active)!;
  }

  get isMobileWallet() {
    return this.accountsStore.accounts.find(
      ({ address, isMobile }) => address === this.selectedWalletAddress && isMobile
    );
  }

  get isExportPossible() {
    return !this.isMobileWallet && this.isExtension;
  }

  get top() {
    return this.buttonTopClick - 30;
  }

  close() {
    this.$emit('close');
  }

  async deleteWallet() {
    await forgetAccount(this.selectedWalletAddress, this.isMobileWallet ? 'mobile' : 'native');

    if (this.accountsStore.accounts.length === 0) this.$router.push({ name: Components.Welcome });
    else this.close();
  }

  exportToGoogleDrive() {
    initGoogleAuth('export', this.selectedWalletAddress);
  }

  async openWalletDetails() {
    await updateCurrentAccount(this.selectedWalletAddress);

    this.$router.push({ name: Components.AccountSetting });

    this.$emit('closeSelectWalletPopup');
  }
}
