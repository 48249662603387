
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import { FPNumber } from '@sora-substrate/util';
import { useAccountsStore } from '@/stores/accounts';

@Component
export default class SelectInput extends Vue {
  accountsStore = useAccountsStore();
  inputIsFocused = false;

  @Prop({ default: '' }) text!: string;
  @Prop({ default: '' }) asset!: string;
  @Prop({ default: '' }) assetId!: string;
  @Prop({ default: '' }) value!: string;
  @Prop({ default: 0 }) totalAmount!: number;
  @Prop({ default: true }) showBalance!: boolean;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: true }) showIcon!: boolean;
  @Prop({ default: false }) showOriginValue!: boolean;
  @PropSync('amount', { type: String }) syncedAmount!: string;
  @PropSync('isRotate', { type: Boolean }) syncedIsRotate!: boolean;

  get showIconRotate() {
    return this.showIcon && !this.readonly;
  }

  get isSelected() {
    return this.inputIsFocused && !this.readonly;
  }

  get amountInternal() {
    if (this.syncedAmount === '') return '';

    if (this.showOriginValue) return this.syncedAmount;

    if (this.syncedAmount.includes('.')) {
      const [wholePart, fractionalPart] = this.syncedAmount.split('.');
      const localString = FPNumber.fromCodecValue(wholePart || 0, 0).toLocaleString();

      if (this.syncedAmount.endsWith('.')) return `${localString}.`;

      if (localString === 'NaN') return this.syncedAmount;

      return `${localString}.${fractionalPart}`;
    }

    return FPNumber.fromCodecValue(this.syncedAmount || 0, 0).toLocaleString();
  }

  set amountInternal(_value: string) {
    const value = _value.replaceAll(',', '').replaceAll(' ', '');

    if (value.length < this.syncedAmount.length) {
      this.syncedAmount = value;

      return;
    }

    if (FPNumber.fromCodecValue(value || 0, 0).toLocaleString() !== 'NaN') {
      if (value.includes('.')) {
        const [wholePart, fractionalPart] = value.split('.');

        if (value.endsWith('.')) {
          this.syncedAmount = this.syncedAmount = `${wholePart}.`;

          return;
        }

        this.syncedAmount = `${wholePart}.${fractionalPart}`;

        return;
      }

      this.syncedAmount = value;
    }
  }

  get header() {
    return this.$t(this.text);
  }

  get tokenGroup() {
    return this.accountsStore.balances.find(({ groupId }) => groupId === this.assetId);
  }

  get assetIcon() {
    return this.tokenGroup?.icon;
  }

  get valueCut() {
    return this.$n(+this.value, 'price');
  }

  get balanceValueClasses() {
    return [
      'balance-value',
      {
        'balance-value-readonly': this.readonly,
      },
    ];
  }

  get selectButtonClasses() {
    return [
      'select-button',
      {
        'select-button-readonly': this.readonly,
      },
    ];
  }

  get selectClasses() {
    return [
      'select',
      {
        'select-focused': this.inputIsFocused && !this.readonly,
      },
    ];
  }

  IsNumber(event: KeyboardEvent) {
    if (!/\d/.test(event.key) && event.key !== '.') return event.preventDefault();
  }

  setFocusValue(value: boolean) {
    this.inputIsFocused = value;

    if (!value) {
      const [wholePart, fractionalPart] = this.syncedAmount.split('.');

      if (+fractionalPart === 0) this.syncedAmount = wholePart;
    }
  }

  setMax() {
    if (this.readonly) return;

    this.$emit('setMax');
  }

  click() {
    if (this.readonly) return;

    this.$emit('togglePopupVisibility');
  }
}
