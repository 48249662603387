
import { Component, Vue } from 'vue-property-decorator';
import { Components } from '@/router/routes';

@Component
export default class AddEthereumAccountPopup extends Vue {
  openAddWalletPage(type: string) {
    this.$router.push({
      name: Components.AddWallet,
      params: {
        type,
        onlyEthereumAccount: '',
      },
    });
  }
}
