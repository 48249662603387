
import { Component, Vue, Prop } from 'vue-property-decorator';
import NetworkItem from './NetworkItem.vue';
import type { Tab } from '@/interfaces/ui';
import { isNetworkGroup } from '@/helpers/common';
import { ALL_NETWORKS, FAVORITE_NETWORKS, POPULAR_NETWORKS } from '@/consts/networks';
import { updateCurrentNetwork } from '@/extension/messaging';
import BaseApi from '@/util/BaseApi';
import { IS_POPUP } from '@/consts/globalClient';
import { useNetworksStore } from '@/stores/networks';
import { useAccountsStore } from '@/stores/accounts';

type Tabs = {
  [ALL_NETWORKS]: Tab;
  [POPULAR_NETWORKS]: Tab;
  [FAVORITE_NETWORKS]: Tab;
};

@Component({
  components: {
    NetworkItem,
  },
})
export default class NetworkManagement extends Vue {
  readonly tabs: Tabs = {
    [ALL_NETWORKS]: {
      label: 'header.networkManagement.tabs.all',
      name: ALL_NETWORKS,
    },
    [POPULAR_NETWORKS]: {
      label: 'header.networkManagement.tabs.popular',
      name: POPULAR_NETWORKS,
    },
    [FAVORITE_NETWORKS]: {
      label: 'header.networkManagement.tabs.favorites',
      name: FAVORITE_NETWORKS,
    },
  };

  accountsStore = useAccountsStore();
  networksStore = useNetworksStore();
  filterValue = '';
  activeTab: keyof Tabs = ALL_NETWORKS;
  value = '';

  @Prop(String) type!: keyof Tabs | string;

  get isGroupSelected() {
    return this.accountsStore.selectedNetwork === this.activeTab;
  }

  get networkListClasses() {
    return IS_POPUP ? '' : 'container--fullscreen';
  }

  get networkGroup() {
    return { name: this.$t(`header.networkManagement.${this.activeTab}`), icon: 'all-networks' };
  }

  get filterByGroupNetworks() {
    if (this.activeTab === ALL_NETWORKS) return this.networksStore.networks;

    if (this.activeTab === POPULAR_NETWORKS) {
      return this.networksStore.networks
        .filter(({ rank }) => rank !== undefined)
        .sort((a, b) => {
          if (a.rank === undefined || b.rank === undefined) return 0;

          return a.rank > b.rank ? 1 : -1;
        });
    }

    return this.networksStore.networks.filter(({ favorite }) =>
      favorite.some((address) => address === this.accountsStore.selectedWallet.address)
    );
  }

  get sortAvailableNetworks() {
    return this.filterByGroupNetworks.sort((a, b) => {
      const aAvailable = this.isAvailableNetwork(a.name);
      const bAvailable = this.isAvailableNetwork(b.name);

      return aAvailable === bAvailable ? 0 : aAvailable === true ? -1 : 1;
    });
  }

  get filteredOptionsNetworks() {
    const filter = this.filterValue.trim().toLowerCase();

    return this.sortAvailableNetworks.filter(({ name }) => name.toLowerCase().includes(filter));
  }

  get isNetworksExists() {
    return this.filteredOptionsNetworks.length !== 0;
  }

  changeFilterValue(value: string) {
    this.filterValue = value;
  }

  getLocale(key: string): string {
    return `header.networkManagement.${key}`;
  }

  async mounted() {
    if (isNetworkGroup(this.accountsStore.selectedNetwork))
      this.activeTab = this.accountsStore.selectedNetwork as keyof Tabs;
  }

  get selectedAccount() {
    return this.accountsStore.accounts.find((el) => el.address === this.accountsStore.selectedWallet.address);
  }

  isAvailableNetwork(network: string): boolean {
    const selectedNetwork = this.networksStore.getNetwork(network);

    if (this.accountsStore.selectedWallet.isMobile) {
      if (!this.selectedAccount) return false;

      if (!this.selectedAccount.chains) return false;

      const available = this.selectedAccount.chains.some((el) => selectedNetwork.chainId.includes(el));

      return available;
    }

    if (this.accountsStore.selectedWallet.ethereumAddress === '' && BaseApi.isEthereumNetwork(network)) return false;

    return true;
  }

  isNetworkSelected(name: string) {
    return this.accountsStore.selectedNetwork === name;
  }

  updateActiveTab(tab: Tab) {
    this.activeTab = tab.name as keyof Tabs;
  }

  toggleNetworkType() {
    if (this.isGroupSelected) return;

    const network = this.tabs[this.activeTab].name;

    this.accountsStore.setSelectedNetwork(network);

    const prepNotification = this.$t(this.getLocale('groupSelected'), {
      group: this.$t(this.tabs[this.activeTab].label),
    }).toString();

    this.$notify({ title: prepNotification, message: '', type: 'success' });
  }

  enableSingleNetwork(network: string) {
    const isSelected = this.isNetworkSelected(network);

    if (isSelected) return;

    this.accountsStore.setSelectedNetwork(network);

    const prepNotification = this.$t(this.getLocale('networkSelected'), { network }).toString();

    this.$notify({ title: prepNotification, message: '', type: 'success' });
  }

  async toggleFavorite(network: string) {
    const isFavorite = await this.networksStore.toggleFavoriteNetwork({
      networkName: network,
      address: this.accountsStore.selectedWallet.address,
    });

    const t = this.getLocale(isFavorite ? 'deleteFavorite' : 'addFavorite');
    const prepNotification = this.$t(t, { network });

    this.$notify({
      title: prepNotification as string,
      message: '',
      type: 'success',
    });
  }

  beforeDestroy() {
    updateCurrentNetwork(this.accountsStore.selectedNetwork);
  }
}
