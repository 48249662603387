
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import type { NetworkJson } from '@extension-base/types';
import { upsertNetworkMap } from '@/extension/messaging';
import { useNetworksStore } from '@/stores/networks';

@Component
export default class EditNodeForm extends Vue {
  networksStore = useNetworksStore();
  name = '';
  url = '';
  isError = false;

  @Prop(String) network!: string;
  @Prop({ type: String, default: '' }) nodeName!: string;
  @Prop({ type: String, default: '' }) nodeUrl!: string;
  @Prop(Boolean) isActive!: boolean;

  get buttonText() {
    return this.isEdit ? 'common.save' : 'accounts.addNode';
  }

  get networkJson() {
    return this.networksStore.getNetwork(this.network);
  }

  get isEdit() {
    return this.nodeUrl !== '';
  }

  get isUrlDuplicate() {
    if (!this.networkJson) return false;

    return (
      this.networkJson.nodes.some(({ url }) => url === this.url) ||
      this.networkJson.customNodes.some(({ url }) => url === this.url)
    );
  }

  get urlLength() {
    return this.url.length;
  }

  @Watch('url')
  isErrorUrlNode() {
    this.isError = false;

    if (this.urlLength === 0 || this.url === this.nodeUrl) return;

    if (this.isUrlDuplicate) {
      this.isError = true;

      return;
    }

    const explorers = this.networkJson.externalApi?.explorers;
    const isTooLengthTooSmall = this.urlLength < 7;

    if (explorers && explorers[0].type === 'etherscan') {
      this.isError = isTooLengthTooSmall || !this.url.startsWith('https://');

      return;
    }

    this.isError = isTooLengthTooSmall || !this.url.startsWith('wss://');
  }

  get errorMessage() {
    if (this.isError) return 'accounts.invalidNodeAddress';

    return 'accounts.customNodeDuplicate';
  }

  get isUrlChanged() {
    return this.url !== this.nodeUrl;
  }

  get isNameChanged() {
    return this.name !== this.nodeName;
  }

  get buttonDisabled() {
    return this.name === '' || this.urlLength === 0 || this.isError || (!this.isUrlChanged && !this.isNameChanged);
  }

  changeName(value: string) {
    this.name = value;
  }

  changeUrl(value: string) {
    this.url = value;
  }

  mounted() {
    this.name = this.nodeName;
    this.url = this.nodeUrl;
  }

  updateNodes() {
    if (this.urlLength === 0) return;

    const prepData: Partial<NetworkJson> = {};
    const customNodeIndex = this.networkJson.customNodes.findIndex(
      ({ url, name }) => url === this.nodeUrl && name === this.nodeName
    );

    prepData.customNodes = this.networkJson.customNodes ?? [];
    const editedNode = { name: this.name, url: this.url };

    if (customNodeIndex >= 0) prepData.customNodes[customNodeIndex] = editedNode;
    else prepData.customNodes.push(editedNode);

    prepData.currentProvider = this.url;

    upsertNetworkMap({
      ...this.networkJson,
      ...prepData,
      isManual: false,
    });

    this.$emit('closeForm', true);
  }
}
