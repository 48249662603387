
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class MnemonicColumns extends Vue {
  @Prop(Array) mnemonicArray!: string[];
  @Prop({ default: 12 }) mnemonicLength!: number;

  get midpoint() {
    return Math.ceil(this.mnemonicLength / 2);
  }

  get mnemonicArrayValidLength() {
    const array = [...this.mnemonicArray];

    array.length = this.mnemonicLength;

    return array.fill('', this.mnemonicArray.length, this.mnemonicLength);
  }

  get mnemonicOne() {
    return this.mnemonicArrayValidLength.slice(0, this.midpoint);
  }

  get mnemonicTwo() {
    return this.mnemonicArrayValidLength.slice(this.midpoint, this.mnemonicLength);
  }

  getNumberString(number: number) {
    return number.toString().padStart(2, '0');
  }
}
