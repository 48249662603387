
import { Component, Vue, Prop } from 'vue-property-decorator';

import type { HistoryElement } from '@/interfaces/history';

import type { SoraHistoryElement } from '@/interfaces';
import { getType, getSignTransfer, getHistoryValue, getHumanTransferFee } from '@/helpers/history';
import { cut, getFormattedDate, setClipboard } from '@/helpers';

import BaseApi from '@/util/BaseApi';
import { useNetworksStore } from '@/stores/networks';
import { useAccountsStore } from '@/stores/accounts';

@Component({})
export default class HistoryDetailsForm extends Vue {
  networksStore = useNetworksStore();
  accountsStore = useAccountsStore();

  @Prop(String) assetId!: string;
  @Prop(String) historyType!: string;
  @Prop(Object) historyElement!: HistoryElement;

  get showTargetAmount() {
    return this.isSora && (this.historyElement as unknown as SoraHistoryElement).method === 'swap';
  }

  get isTransfer() {
    return this.type === 'transfer';
  }

  get networkProps() {
    return this.networksStore.getNetwork(this.selectedNetwork);
  }

  get address() {
    if (BaseApi.isEthereumNetwork(this.selectedNetwork)) return this.accountsStore.selectedWallet.ethereumAddress;

    return BaseApi.encodeAddress(this.accountsStore.selectedWallet.address, this.networkProps.addressPrefix);
  }

  get selectedNetworkJson() {
    return this.networksStore.networks.find(
      (network) => network.name.toLowerCase() === this.selectedNetwork.toLowerCase()
    );
  }

  get explorerType() {
    return this.selectedNetworkJson?.externalApi?.history?.type;
  }

  get haveExplorers() {
    return this.explorerUrl !== '';
  }

  get explorerUrl() {
    return this.networkProps?.externalApi?.explorers?.[0].url ?? '';
  }

  get buttonText() {
    return this.$t(this.explorerType === 'etherscan' ? 'accounts.etherscan' : 'accounts.subscan');
  }

  get isReward() {
    return this.type === 'reward';
  }

  get showFee() {
    if (this.isSora) return (this.historyElement as unknown as SoraHistoryElement).method !== 'rewarded';

    return this.isTransfer && this.signTransfer === '-';
  }

  get isSora() {
    return this.type === 'sora';
  }

  get showAmount() {
    if (this.isSora) return true;

    return this.isTransfer;
  }

  get statusIsSuccess() {
    if (this.isSora) return (this.historyElement as unknown as SoraHistoryElement).execution.success;

    const { success } = this.historyElement!;

    return success ?? true;
  }

  get validator() {
    return this.historyElement.reward?.validator;
  }

  get displayValidator() {
    if (!this.validator) return 'no validator info';

    return cut(this.validator, 10);
  }

  get era() {
    return this.historyElement.reward?.era;
  }

  get statusClasses() {
    return ['item-value', this.statusIsSuccess ? 'status-success' : 'status-reject'];
  }

  get statusText() {
    return this.statusIsSuccess ? 'Completed' : 'Reject';
  }

  get fromAddress() {
    return this.historyElement.transfer!.from;
  }

  get displayFromAddress() {
    return cut(this.fromAddress, 10);
  }

  get toAddress() {
    return this.historyElement.transfer!.to;
  }

  get displayToAddress() {
    return cut(this.toAddress, 10);
  }

  get moduleType() {
    if (this.isSora) return (this.historyElement as unknown as SoraHistoryElement).module;

    return this.historyElement!.module;
  }

  get method() {
    if (this.isSora) return (this.historyElement as unknown as SoraHistoryElement).method;

    return this.historyElement?.method;
  }

  get transferFee() {
    const fees = getHumanTransferFee(this.historyElement, this.selectedNetwork);

    return this.$n(fees, 'decimalPrecise');
  }

  get date() {
    return getFormattedDate(this.historyElement.timestamp);
  }

  get value() {
    const { value } = getHistoryValue(this.historyElement, this.assetId, this.selectedNetwork, this.address);

    return this.$n(value, 'decimalPrecise');
  }

  get targetValue() {
    const { targetValue } = getHistoryValue(this.historyElement, this.assetId, this.selectedNetwork, this.address);

    return this.$n(targetValue!, 'decimalPrecise');
  }

  get type() {
    return getType(this.historyElement, this.selectedNetwork);
  }

  get signTransfer() {
    return getSignTransfer(this.historyElement, this.address, this.selectedNetwork);
  }

  get extrinsicHash() {
    return this.historyElement.extrinsicHash;
  }

  get displayExtrinsicHash() {
    return cut(this.extrinsicHash);
  }

  get blockHash() {
    return this.historyElement.blockHash;
  }

  get displayBlockHash() {
    return cut(this.blockHash);
  }

  get selectedNetwork() {
    // TODO Переделать на одинаковое название параметра
    return this.$route.params.network ?? this.$route.params.selectedNetwork;
  }

  copy(value?: string) {
    if (!value) return;

    setClipboard(value);
  }

  openExplorer() {
    if (this.explorerType === 'etherscan' || this.explorerType === 'oklink') {
      if (this.explorerUrl) {
        const url = this.explorerUrl.replace('{type}', 'tx').replace('{value}', this.historyElement?.blockHash ?? '');

        window.open(url);
      }

      return;
    }

    const url = this.explorerUrl
      .replace('{type}', 'extrinsic')
      .replace('{value}', this.historyElement?.blockHash ?? '');

    window.open(url);
  }
}
