import { render, staticRenderFns } from "./AssetInfo.vue?vue&type=template&id=7548cf79&scoped=true&"
import script from "./AssetInfo.vue?vue&type=script&lang=ts&"
export * from "./AssetInfo.vue?vue&type=script&lang=ts&"
import style0 from "./AssetInfo.vue?vue&type=style&index=0&id=7548cf79&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7548cf79",
  null
  
)

export default component.exports