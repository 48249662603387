
import { Component, Vue, Prop } from 'vue-property-decorator';

import type { ChangeWalletBalance } from '@/interfaces';
import { useAccountsStore } from '@/stores/accounts';

@Component
export default class WalletBalance extends Vue {
  accountsStore = useAccountsStore();

  @Prop(Object) changeWalletBalance!: ChangeWalletBalance;
  @Prop(Number) balance!: number;
  @Prop({ default: true }) staticWidth!: boolean;

  get containerClasses() {
    const array = [
      'wallet-balance',
      {
        'wallet-balance--static': this.staticWidth,
      },
    ];

    return array;
  }

  get percentString() {
    const { percent, amount } = this.changeWalletBalance;

    if (percent === 0) return `${this.$n(0, 'percent')}`;

    const sign = percent > 0 ? '+' : '';
    const displayAmount = amount < 0 ? amount * -1 : amount;
    const percentage = percent / 100 ?? 0;

    return `${sign}${this.$n(percentage, 'percent')}(${this.accountsStore.fiatSymbol}${this.$n(
      displayAmount ?? 0,
      'price'
    )})`;
  }

  get percentClasses() {
    const { percent } = this.changeWalletBalance;
    const classes = ['percent'];

    if (percent > 0) classes.push('up-percent');
    else if (percent < 0) classes.push('down-percent');

    return classes;
  }
}
