
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { storage } from '@extension-base/stores/Storage';
import { toSvg } from 'jdenticon';
import { type AddressBook } from '@extension-base/background/types/types';
import BaseApi from '@/util/BaseApi';
import { cut, isSora } from '@/helpers/';
import { getType } from '@/helpers/history';
import { type SoraHistoryElement, TransactionType } from '@/interfaces/history';
import { useNetworksStore } from '@/stores/networks';

@Component
export default class HistoryBook extends Vue {
  networksStore = useNetworksStore();
  addressBook: AddressBook = {};

  @Prop(String) network!: string;
  @Prop(String) assetId!: string;

  get showHistoryAndBook() {
    return this.showHistory || this.book.length !== 0;
  }

  get showHistory() {
    return this.historyAddresses.length !== 0;
  }

  get addressPrefix() {
    return this.networksStore.getNetwork(this.network)?.addressPrefix;
  }

  get book() {
    const addresses = [...(this.addressBook['all'] ?? []), ...(this.addressBook[this.network] ?? [])];

    return Array.from(new Set(addresses));
  }

  get historyAddresses() {
    if (!this.network) return [];

    const history = this.networksStore.getHistory(this.assetId, this.network.toLowerCase());

    if (!history) return [];

    const addresses = isSora(this.network)
      ? (history.nodes as unknown as SoraHistoryElement[]).flatMap((item) => {
          if (item.method !== 'transfer') return [];

          return BaseApi.encodeAddress(item.data?.to ?? '', this.addressPrefix) ?? [];
        })
      : history?.nodes.flatMap((item) => {
          if (getType(item) !== TransactionType.transfer) return [];

          return BaseApi.encodeAddress(item.transfer?.to ?? '', this.addressPrefix) ?? [];
        });

    return Array.from(new Set(addresses))
      .filter(
        (address) =>
          !this.book.some(
            ({ address: addressFromBook }) => BaseApi.encodeAddress(address) === BaseApi.encodeAddress(addressFromBook)
          )
      )
      .slice(0, 11);
  }

  get splitAddressBook() {
    const sortedAddressBook = this.book.sort(({ name: name1 }, { name: name2 }) => name1.localeCompare(name2));

    const splitObj = sortedAddressBook.reduce<AddressBook>((result, { address, name }) => {
      const firstChar = name[0].toUpperCase();
      const addressByNetwork = BaseApi.encodeAddress(address, this.addressPrefix);

      if (result[firstChar]) result[firstChar].push({ name, address: addressByNetwork });
      else result[firstChar] = [{ name, address: addressByNetwork }];

      return result;
    }, {});

    return Object.entries(splitObj);
  }

  @Watch('assetId')
  @Watch('selectedNetwork')
  networkWatcher() {
    this.loadHistory();
  }

  async mounted() {
    this.loadHistory();

    const { addressBook } = await storage.get(['addressBook']);

    this.addressBook = addressBook;
  }

  loadHistory() {
    if (this.historyAddresses.length !== 0) return;

    this.networksStore.fetchHistory({ networkName: this.network, assetId: this.assetId });
  }

  getJdenticon(address: string) {
    return toSvg(address, 24);
  }

  cut(value: string) {
    return cut(value);
  }

  setRecipient(address: string) {
    this.$emit('setRecipient', address);
    this.$emit('toggleHistoryBookVisibility');
  }

  openEditBook(address: string = '') {
    this.$emit('toggleEditBook', address);
  }
}
