
import { Component, Vue, Prop } from 'vue-property-decorator';
import Popup from './Popup.vue';

type SpaceSize = 'small' | 'medium' | 'big';

interface Options {
  name: string;
  value: string;
  icon: string;
  iconType: string;
  isAll?: true;
}

@Component({
  components: { Popup },
})
export default class SelectPopup extends Vue {
  icons: string[] = [];
  formattedOptions: Record<string, string>[] = [];

  @Prop(String) value!: string;
  @Prop(Array) options!: Options[];
  @Prop(String) headerText!: string;
  @Prop(Number) top!: number;
  @Prop(Number) left!: number;
  @Prop({ default: 'center' }) horizontalPlacement!: string;
  @Prop({ default: 'center' }) verticalPlacement!: string;
  @Prop({ default: 'big' }) space!: SpaceSize;
  @Prop({ default: '' }) placeholder!: string;
  @Prop({ type: Number, required: false }) height?: number;
  @Prop({ type: Number, required: false }) maxHeight?: number;
  @Prop({ default: true }) showIcon!: boolean;
  @Prop({ default: true }) showSearch!: boolean;
  @Prop({ default: true }) showBorder!: boolean;
  @Prop({ default: true }) showBlur!: boolean;
  @Prop({ default: true }) showAnimation!: boolean;
  @Prop({ default: true }) showBackground!: boolean;
  @Prop({ default: 'medium' }) sizeWidth!: boolean;

  get showWarning() {
    return this.options.length === 0;
  }

  created() {
    const index = this.options.findIndex(({ value }) => value === this.value);

    if (index === -1) return;

    const selectedElement = this.options[index];
    const indexInsertion = this.options[0]?.isAll && index !== 0 ? 1 : 0;

    this.options.splice(index, 1);
    this.options.splice(indexInsertion, 0, selectedElement);
  }

  getIconVisible(value: string) {
    return this.value === value;
  }

  rowClasses(value: string) {
    return [
      'row',
      {
        'row-active': this.value === value,
      },
      `padding-${this.space}`,
    ];
  }

  toggle(value: string) {
    this.$emit('toggleValue', value);
  }

  isAddressIconType(iconType: string) {
    return iconType === 'address';
  }

  isGlobusIcon(icon: string) {
    return icon === 'globus';
  }
}
