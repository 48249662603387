
import { Component, Vue } from 'vue-property-decorator';
import { VerificationStatus } from '@/consts/soraCard';
import { Components } from '@/router/routes';
import { windowOpen } from '@/extension/messaging';
import { IS_POPUP } from '@/consts/globalClient';
import { useAccountsStore } from '@/stores/accounts';
import { useSoraCardStore } from '@/stores/soraCard';

@Component({})
export default class SoraCardBanner extends Vue {
  accountsStore = useAccountsStore();
  soraCardStore = useSoraCardStore();

  get statusText() {
    if (this.soraCardStore.currentStatus !== null && this.soraCardStore.currentStatus !== VerificationStatus.None)
      return `soraCard.statuses.${this.soraCardStore.currentStatus?.toLowerCase()}.statusText`;

    return 'soraCard.getCard';
  }

  hideBanner() {
    this.accountsStore.setSoraCardBannerVisibility(false);
  }

  openSoraCardForm() {
    if (IS_POPUP) windowOpen('/sora-card');

    this.$router.push({ name: Components.SoraCard });
  }
}
