const SEC1 = 1000;
const MIN1 = SEC1 * 60;
const HOUR1 = MIN1 * 60;
const DAY1 = HOUR1 * 24;
const WEEK1 = DAY1 * 7;
const YEAR1 = DAY1 * 365;

const MIN30 = MIN1 * 30;

export { SEC1, MIN1, HOUR1, DAY1, WEEK1, YEAR1, MIN30 };
