import { storage } from '@extension-base/stores/Storage';
import axios from 'axios';
import type { UserType } from './types';
import type { OnBoardingStoriesLocales, OnboardingStories } from '@/interfaces';
import { IS_PRODUCTION, IS_TEST_ONLY } from '@/consts/global';
import { URLS } from '@/consts/urls';

export class OnboardingService {
  private stories: OnBoardingStoriesLocales = {};
  private defaultLocale = 'en-EN';
  public userType: UserType = 'new';
  public isRequired = false;

  async init(): Promise<void> {
    if (!IS_PRODUCTION && !IS_TEST_ONLY) return;

    const { onboarding } = await storage.get(['onboarding']);

    if (onboarding) this.changeUserType(onboarding.user);

    const res = await axios
      .get<OnBoardingStoriesLocales>(URLS.ONBOARDING_URL)
      .catch(() => console.info('onboarding fetch error'));

    if (res?.status !== 200) return;

    this.stories = res.data;

    const userStories = this.stories[this.defaultLocale]?.[this.userType];

    this.isRequired = onboarding?.isRequired ?? userStories.length !== 0;

    this.updateStorage();
  }

  getStories(lang: string): OnboardingStories {
    const localizeStories = this.stories[lang] ?? this.stories[this.defaultLocale];

    return localizeStories?.[this.userType] ?? [];
  }

  changeUserType(type: UserType) {
    this.userType = type;
  }

  setComplete() {
    this.isRequired = false;

    this.changeUserType('regular');
    this.updateStorage();
  }

  async updateStorage(user: UserType = this.userType, isRequired: boolean = this.isRequired) {
    await storage.set({ onboarding: { user, isRequired } });
  }
}
