
import { Component, Vue, Prop } from 'vue-property-decorator';

import type { RequestStaking } from '@extension-base/services/staking-service/types';
import type { NftTx } from '@extension-base/services/nft-service/types';
import type {
  RequestCheckTransfer,
  RequestCheckCrossChain,
  RequestTransfer,
  RequestCrossChain,
  TokenGroup,
  RequestSwap,
  BasicTxResponse,
  ResponseMakeSwap,
  ResponseNftTransfer,
} from '@extension-base/background/types/types';
import type { SwapOptions, StakingOperation } from '@/interfaces';
import type { RequestPool } from '@extension-base/services/pools-service/types';
import type { PoolsOperation } from '@/interfaces/pools';
import { makeSwap, makeTransfer, makeCrossChain, makeStaking, makePool } from '@/extension/messaging';
import BaseApi from '@/util/BaseApi';
import SignMobile from '@/screens/wallet&asset/SignMobile.vue';
import { IS_EXTENSION } from '@/consts/global';
import { sendNft } from '@/extension/messaging/nfts';
import { isSora, setClipboard } from '@/helpers';
import { useNetworksStore } from '@/stores/networks';
import { useAccountsStore } from '@/stores/accounts';

@Component({
  components: { SignMobile },
})
export default class ConfirmationPasswordPopup extends Vue {
  readonly isExtension = IS_EXTENSION;
  networksStore = useNetworksStore();
  accountsStore = useAccountsStore();

  hash: string | undefined = undefined;
  signedPayload: null = null;
  transactionState: 'pending' | 'success' | 'failed' | null = null;
  showUnknownErrorPopup = false;

  @Prop({ type: String, default: '0' }) amount!: string;
  @Prop({ type: String, default: '0' }) value!: string;
  @Prop({ type: String, default: '0' }) fee!: string;
  @Prop({ type: String, default: '0' }) feeValue!: string;
  @Prop(String) firstIcon!: string;
  @Prop(String) secondIcon!: string;
  @Prop(Object) currency?: TokenGroup;
  @Prop(Object) tx!: RequestCheckTransfer | RequestCheckCrossChain | RequestStaking | SwapOptions | NftTx | RequestPool;
  @Prop(String) extrinsicType!: 'transfer' | 'crossChain' | 'swap' | 'nft' | StakingOperation | PoolsOperation;

  get firstIconUrl() {
    if (this.extrinsicType === 'crossChain')
      return (
        this.networksStore.networks.find(({ name }) => name.toLowerCase() === this.firstIcon.toLowerCase())?.icon ?? ''
      );

    const tokenGroup = this.accountsStore.balances.find(({ groupId }) => groupId === this.firstIcon);

    if (tokenGroup) return tokenGroup.icon;

    return this.firstIcon;
  }

  get secondIconUrl() {
    if (this.extrinsicType === 'crossChain')
      return (
        this.networksStore.networks.find(({ name }) => name.toLowerCase() === this.secondIcon.toLowerCase())?.icon ?? ''
      );

    const tokenGroup = this.accountsStore.balances.find(({ groupId }) => groupId === this.secondIcon);

    if (tokenGroup) return tokenGroup.icon;

    return this.secondIcon;
  }

  get request() {
    return {
      ...this.tx,
      isMobile: this.isSignMobile,
    };
  }

  get transactionAddress() {
    return this.accountsStore.selectedWallet.address;
  }

  get isSignMobile() {
    const encodedAddress = BaseApi.encodeAddress(this.transactionAddress);

    return this.accountsStore.accounts.some((account) => account.address === encodedAddress && account.isMobile);
  }

  get isSuccess() {
    return this.transactionState === 'success';
  }

  get isFailed() {
    return this.transactionState === 'failed';
  }

  get headerType() {
    if (this.isSuccess) return 'success';

    if (this.isFailed) return 'failed';

    return 'pending';
  }

  get popupHeader() {
    if (this.isSuccess) return 'assets.transactionDone';

    if (this.isFailed) return 'assets.transactionError';

    if (this.isTransactionPending) return 'assets.transactionPending';

    return '';
  }

  get transferAmountString() {
    const sumValue = +this.amount + +this.fee;
    const value = this.isSuccess ? sumValue : +this.fee;

    return `-${this.$n(value, 'decimal')} ${this.currency?.symbol.toUpperCase()}`;
  }

  get transferValueString() {
    const sumValue = +this.value + +this.feeValue;
    const value = this.isSuccess ? sumValue : +this.feeValue;

    return `${this.accountsStore.fiatSymbol}${this.$n(value, 'price')}`;
  }

  get isTransactionInit() {
    return this.transactionState !== null;
  }

  get isTransactionPending() {
    return this.transactionState === 'pending';
  }

  get isTransactionFinished() {
    return this.isSuccess || this.isFailed;
  }

  get isPool() {
    return this.extrinsicType === 'addLiquidity' || this.extrinsicType === 'removeLiquidity';
  }

  get isStaking() {
    return (
      this.extrinsicType === 'bond' ||
      this.extrinsicType === 'bondExtra' ||
      this.extrinsicType === 'unbond' ||
      this.extrinsicType === 'rebond' ||
      this.extrinsicType === 'redeem' ||
      this.extrinsicType === 'nominate' ||
      this.extrinsicType === 'setController' ||
      this.extrinsicType === 'setPayee' ||
      this.extrinsicType === 'payoutRewards'
    );
  }

  async mounted() {
    this.resetTxStatus();
    this.sendExtrinsic();
  }

  resetTxStatus() {
    this.transactionState = null;
  }

  close() {
    this.$emit('close', this.isTransactionInit);

    if (this.isTransactionPending || this.isTransactionFinished) this.resetTxStatus();
  }

  copyHash() {
    setClipboard(this.hash ?? '');
  }

  async onSignMobile() {
    if (this.extrinsicType === 'swap') await makeSwap(this.request as RequestSwap);
    else this.makeExtrinsic();
  }

  async makeExtrinsic(): Promise<BasicTxResponse | ResponseMakeSwap | ResponseNftTransfer | undefined> {
    const callback = (data: any) => {
      // TODO Выводить юзеру ошибку ???
      // TODO ошибку balanceTooLow по хорошему нужно обработать и показать
      console.info('errors:', data.errors ?? []);

      // транзакция может не пройти даже после отправки в блокчейн
      this.transactionState = data.status ? 'success' : 'failed';
    };

    if (this.extrinsicType === 'transfer') return makeTransfer(this.request as RequestTransfer, callback);

    if (this.extrinsicType === 'crossChain') return makeCrossChain(this.request as RequestCrossChain, callback);

    if (this.extrinsicType === 'swap') return makeSwap(this.request as RequestSwap);

    if (this.extrinsicType === 'nft') return sendNft(this.request as NftTx);

    if (this.isStaking)
      return makeStaking({
        type: this.extrinsicType as StakingOperation,
        params: this.request as RequestStaking,
      });

    if (this.isPool)
      return makePool({
        type: this.extrinsicType as PoolsOperation,
        params: this.request as RequestPool,
      });
  }

  openExplorer() {
    const network = this.networksStore.getNetwork((this.tx as NftTx).network);
    const explorerUrl = network.externalApi?.explorers ? network?.externalApi?.explorers[0].url : '';

    const hostname = new URL(explorerUrl).hostname;

    window.open(`https://${hostname}/tx/${this.hash}`);
  }

  async sendExtrinsic() {
    this.transactionState = 'pending';

    const results = await this.makeExtrinsic();

    if (this.extrinsicType === 'nft') {
      const result = results as ResponseNftTransfer;

      this.hash = result.hash;
    }

    const txCross = this.tx as RequestCheckCrossChain;

    // функции выполняются через "@sora-substrate/util, для них не работают колбеки с подпиской
    if (
      this.isStaking ||
      this.isPool ||
      this.extrinsicType === 'swap' ||
      this.extrinsicType === 'nft' ||
      (this.extrinsicType === 'crossChain' && isSora(txCross.originNet))
    )
      this.transactionState = results?.status ? 'success' : 'failed';
  }
}
