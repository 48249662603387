
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import type { TabWallet } from '@/interfaces/common';
import type { TokenGroup } from '@extension-base/background/types/types';
import { Components } from '@/router/routes';
import { IS_EXTENSION } from '@/consts/global';
import { useAccountsStore } from '@/stores/accounts';

interface TabsOptions {
  label: string;
  tabName: TabWallet;
  tooltipText: string;
  classes: string;
  target: string;
}

@Component
export default class ContentSettings extends Vue {
  readonly tabsOptions: TabsOptions[] = [
    {
      label: 'wallet.currencies',
      tabName: Components.Currencies,
      tooltipText: 'wallet.fungibleTokens',
      classes: 'currencies-tab',
      target: '.currencies-tab',
    },
    {
      label: 'wallet.nfts',
      tabName: Components.Nfts,
      tooltipText: 'wallet.nonFungibleTokens',
      classes: 'currencies-tab',
      target: '.currencies-tab',
    },
  ];

  accountsStore = useAccountsStore();

  @PropSync('activeTabName', { type: String }) syncedActiveTabName!: TabWallet;
  @PropSync('filterValue', { type: String }) syncedFilterValue!: string;
  @PropSync('showAssetsManagementForm', { type: Boolean }) syncedShowAssetsManagementForm!: boolean;
  @Prop(Array) balances!: TokenGroup[];

  get target() {
    return `.${this.iconName}`;
  }

  get allCurrenciesHidden() {
    return this.balances.every(({ groupId }) => this.accountsStore.hiddenAssets.includes(groupId));
  }

  get searchInputWidth() {
    return IS_EXTENSION ? '185px' : '100%';
  }

  get toggleButtonText() {
    return this.allCurrenciesHidden ? 'wallet.showAllBalances' : 'wallet.hideZero';
  }

  get iconName() {
    return this.syncedShowAssetsManagementForm ? 'close' : 'filter';
  }

  get notNftTabActive() {
    return this.syncedActiveTabName !== Components.Nfts;
  }

  openTab(name: TabWallet) {
    this.syncedActiveTabName = name;
  }

  changeSyncedFilterValue(value: string) {
    this.syncedFilterValue = value;
  }

  toggleAssetsManagementVisible() {
    this.syncedShowAssetsManagementForm = !this.syncedShowAssetsManagementForm;
  }
}
