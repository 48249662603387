
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { APIItemState } from '@extension-base/api/types/networks';
import type { TokenGroup } from '@extension-base/background/types/types';
import type { AssetPrice } from '@/interfaces';

import { getSummaryTransferableBalanceFilteredByActiveNetworks } from '@/helpers/currencies';
import { getSummaryLockedBalance } from '@/helpers/common';
import BalanceDetailsPopup from '@/screens/wallet&asset/BalanceDetailsPopup.vue';
import AccountSettingsPopup from '@/screens/accounts/AccountSettingsPopup.vue';
import { useAccountsStore } from '@/stores/accounts';

@Component({
  components: {
    BalanceDetailsPopup,
    AccountSettingsPopup,
  },
})
export default class AssetInfo extends Vue {
  accountsStore = useAccountsStore();
  showBalanceDetailsPopup = false;
  showDetailsPopup = false;
  transferableAssetBalance = 0;

  @Prop(Object) price!: AssetPrice;
  @Prop(Object) currency!: TokenGroup;

  get selectedAssetNetwork() {
    return this.$route.params.selectedNetwork;
  }

  get pickedNetwork() {
    return this.selectedAssetNetwork ?? this.accountsStore.selectedNetwork;
  }

  get showShimmers() {
    return !navigator.onLine || !this.currency.balances?.some(({ state }) => state === APIItemState.READY);
  }

  get icon() {
    return this.currency?.icon ?? '';
  }

  get showSettingsPopup() {
    return this.selectedAssetNetwork !== '' && this.selectedAssetNetwork !== undefined;
  }

  get priceChangeString() {
    return this.$n(this.price.priceChange, 'percent');
  }

  get fiatPriceChangeString() {
    return `(${this.accountsStore.fiatSymbol}${this.$n(
      this.transferableFiatBalance * this.price.priceChange,
      'price'
    )})`;
  }

  get changePriceClasses() {
    const classes = ['price-change'];

    if (this.price.priceChange > 0) classes.push('up-price');
    else if (this.price.priceChange < 0) classes.push('down-price');

    return classes;
  }

  get assetPriceString() {
    return `1 ${this.selectedAssetUpper} = ${this.accountsStore.fiatSymbol}${this.$n(this.price.price, 'price')}`;
  }

  get lockedBalanceString() {
    const lockedBalance = getSummaryLockedBalance(this.currency);

    return `${this.$n(lockedBalance, 'decimal')} ${this.selectedAssetUpper}`;
  }

  get transferableFiatBalance() {
    return this.transferableAssetBalance * this.price.price;
  }

  get transferableFiatBalanceInNetworkString() {
    if (!this.currency) return `${this.accountsStore.fiatSymbol} 0`;

    return `${this.accountsStore.fiatSymbol} ${this.$n(this.transferableFiatBalance, 'price')}`;
  }

  get countAssetsString() {
    if (!this.currency) return `0 ${this.selectedAssetUpper}`;

    const total = this.$n(this.transferableAssetBalance, 'decimal');

    return `${total} ${this.selectedAssetUpper}`;
  }

  get selectedAsset() {
    return this.currency.symbol?.toLowerCase() ?? '';
  }

  get selectedAssetUpper() {
    return this.selectedAsset.toUpperCase();
  }

  @Watch('pickedNetwork')
  @Watch('currency', { deep: true })
  transferableAssetBalanceUpdate() {
    this.transferableAssetBalance = +getSummaryTransferableBalanceFilteredByActiveNetworks(
      this.currency,
      this.pickedNetwork
    );
  }

  mounted() {
    this.transferableAssetBalanceUpdate();
  }

  toggleBalanceDetailsPopup() {
    this.showBalanceDetailsPopup = !this.showBalanceDetailsPopup;
  }

  toggleDetailsPopup() {
    this.showDetailsPopup = !this.showDetailsPopup;
  }
}
