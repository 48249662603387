
import { Component, Vue } from 'vue-property-decorator';
import Header from './Header.vue';
import Menu from './Menu.vue';
import SelectWalletPopup from './SelectWalletPopup.vue';
import WalletDetailsPopup from './WalletDetailsPopup.vue';
import SettingsPopup from './SettingsPopup.vue';
import FiatsPopup from './FiatsPopup.vue';
import AboutPopup from './AboutPopup.vue';
import LanguagePopup from './LanguagePopup.vue';
import ManageAuths from '@/screens/extension-ui/ManageAuths.vue';

@Component({
  components: {
    Menu,
    Header,
    FiatsPopup,
    AboutPopup,
    ManageAuths,
    SettingsPopup,
    LanguagePopup,
    SelectWalletPopup,
    WalletDetailsPopup,
  },
})
export default class Main extends Vue {
  buttonTopClick = 0;
  selectedWalletAddress = '';
  showSettings = false;
  showLanguagePopup = false;
  showFiatsPopup = false;
  showAboutPopup = false;
  showSelectWalletPopup = false;
  showWalletDetailsPopup = false;
  showFiatPopupAnimation = false;
  showManageAuthsVisible = false;

  get highlightSettingsIcon() {
    return this.showSettings || this.showAboutPopup || this.showLanguagePopup || this.showFiatsPopup;
  }

  deactivated() {
    this.showSelectWalletPopup = false;
    this.showWalletDetailsPopup = false;
    this.showSettings = false;
  }

  toggleManageAuthsVisible() {
    this.showManageAuthsVisible = !this.showManageAuthsVisible;

    if (this.showManageAuthsVisible) this.toggleSettingsVisible();
  }

  toggleLanguagePopupVisible() {
    this.showLanguagePopup = !this.showLanguagePopup;

    if (this.showLanguagePopup) this.toggleSettingsVisible();
  }

  toggleAboutPopupVisible() {
    this.showAboutPopup = !this.showAboutPopup;

    if (this.showAboutPopup) this.toggleSettingsVisible();
  }

  toggleFiatsPopupVisible(showFiatPopupAnimation = false) {
    this.showFiatPopupAnimation = !this.showFiatsPopup ? showFiatPopupAnimation : false;
    this.showFiatsPopup = !this.showFiatsPopup;

    if (this.showFiatsPopup) this.showSettings = false;
  }

  toggleSettingsVisible() {
    if (!this.showSettings && (this.showFiatsPopup || this.showLanguagePopup || this.showAboutPopup)) {
      this.showFiatsPopup = false;
      this.showAboutPopup = false;
      this.showLanguagePopup = false;

      return;
    }

    this.showSettings = !this.showSettings;
  }

  setSelectWalletPopupVisible(value = false) {
    this.showSelectWalletPopup = value;
    this.showWalletDetailsPopup = false;
  }

  toggleWalletDetailsPopupVisible(value: boolean, buttonTop = 0, address = '') {
    this.showWalletDetailsPopup = value ?? !this.showWalletDetailsPopup;
    this.buttonTopClick = buttonTop;
    this.selectedWalletAddress = address;
  }
}
