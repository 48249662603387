
import { Component, Vue } from 'vue-property-decorator';
import { Components } from '@/router/routes';
import SettingMenuItem from '@/screens/main/SettingMenuItem.vue';

import { IS_EXTENSION } from '@/consts/global';

import { lockExtension } from '@/extension/messaging';
import { useExtensionStore } from '@/stores/extension';

@Component({
  components: { SettingMenuItem },
})
export default class SettingsPopup extends Vue {
  readonly isExtension = IS_EXTENSION;
  extensionStore = useExtensionStore();

  get showSoraCard() {
    return this.extensionStore.features?.fiat?.soraCard;
  }

  get routeName() {
    return this.$route.name;
  }

  openPopup(value: string) {
    this.$emit(value);
  }

  openManageAuths() {
    this.$router.push({ name: Components.DAppsAuths, params: { type: 'substrate' } });
  }

  open(name: keyof typeof Components) {
    if (this.routeName !== name) this.$router.push({ name: Components[name] });

    this.$emit('handlerClose');
  }

  lock() {
    lockExtension(true);

    this.$router.push({ name: Components.Unlock });
  }
}
