
import { Component, Vue } from 'vue-property-decorator';
import WalletInfo from './WalletInfo.vue';
import type { CustomEvent } from '@/interfaces';

import { Components } from '@/router/routes';
import { updateCurrentAccount } from '@/extension/messaging';
import { useAccountsStore } from '@/stores/accounts';

@Component({
  components: { WalletInfo },
})
export default class SelectWalletPopup extends Vue {
  accountsStore = useAccountsStore();

  addWallet() {
    this.$router.push({ name: Components.Welcome });
  }

  walletPopupClick({ target: { classList } }: CustomEvent) {
    if (
      !(
        classList.contains('dots-container') ||
        classList.contains('dots') ||
        classList.contains('dots-horizontal') ||
        classList.contains('icon__inner')
      )
    )
      this.$emit('toggleWalletDetailsPopupVisible', false);
  }

  updateSelectedWallet(address: string) {
    updateCurrentAccount(address);

    this.close();
  }

  close() {
    this.$emit('close');
  }

  toggleWalletDetailsPopupVisible(buttonTop: number, address: string) {
    this.$emit('toggleWalletDetailsPopupVisible', undefined, buttonTop, address);
  }

  get sortedWallets() {
    return this.accountsStore.accounts.sort((a, b) => a.name.localeCompare(b.name));
  }
}
