
import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';

import type { TokenGroup } from '@extension-base/background/types/types';

import NetworkManagementButton from '@/screens/main/NetworkManagementButton.vue';
import NetworkManagement from '@/screens/wallet&asset/NetworkManagement.vue';

import { Components } from '@/router/routes';
import BaseApi from '@/util/BaseApi';
import { windowOpen } from '@/extension/messaging';
import ConnectionPopup from '@/screens/main/ConnectionPopup.vue';
import { isNetworkGroup } from '@/helpers/common';
import { cut, setClipboard } from '@/helpers';
import { IS_POPUP } from '@/consts/globalClient';
import { useExtensionStore } from '@/stores/extension';
import { useNetworksStore } from '@/stores/networks';
import { useAccountsStore } from '@/stores/accounts';

@Component({
  components: {
    ConnectionPopup,
    NetworkManagement,
    NetworkManagementButton,
  },
})
export default class Header extends Vue {
  readonly walletNameRef = 'walletName';
  readonly settingsNameRef = 'settingsName';
  readonly isPopup = IS_POPUP;
  readonly allNetworksIcon = 'all-networks';

  networksStore = useNetworksStore();
  extensionStore = useExtensionStore();
  accountsStore = useAccountsStore();
  showConnectionPopup = false;
  showSelectNetworkPopup = false;

  @Prop(Boolean) highlightSettingsIcon!: boolean;
  @PropSync('showSelectWalletPopup', { type: Boolean }) syncedShowSelectWalletPopup!: boolean;

  get showBackIcon() {
    const route = this.$route.name;

    return route === Components.AssetNetworks || route === Components.AssetHistory;
  }

  get isAddressExists() {
    if (this.isGroup) return this.routeName === Components.AssetHistory;

    return true;
  }

  get routeName() {
    return this.$route.name;
  }

  get routeParams() {
    return this.$route.params;
  }

  get isGroup() {
    return isNetworkGroup(this.accountsStore.selectedNetwork);
  }

  get selectedAssetId() {
    return this.$route.params.assetId ?? '';
  }

  get currentCurrency(): TokenGroup | undefined {
    return this.accountsStore.balances.find(
      ({ groupId, balances }) =>
        groupId === this.selectedAssetId || balances.some((el) => el.id === this.selectedAssetId)
    );
  }

  get computeActiveNetworks() {
    if (!this.currentCurrency) return [];

    return this.currentCurrency.balances.filter((network) => this.networksStore.getNetwork(network.name).active);
  }

  get networkManagementButtonText() {
    if (this.isGroup) {
      if (this.$route.name === Components.AssetHistory)
        return this.networksStore.getNetwork(this.$route.params.selectedNetwork)?.name;

      return this.$t(`header.networkManagement.${this.accountsStore.selectedNetwork}`);
    }

    return this.accountsStore.selectedNetwork;
  }

  get selectedNetworkIcon() {
    if (this.isGroup) return this.allNetworksIcon;

    if (this.$route.name === Components.AssetHistory) {
      const asset = this.currentCurrency?.balances.find((el) => el.id === this.selectedAssetId);
      if (!asset) return '';

      return this.networksStore.getNetwork(asset?.name).icon;
    }

    const network = this.networksStore.getNetwork(this.accountsStore.selectedNetwork);

    if (network) return network.icon;

    return this.allNetworksIcon;
  }

  get cutAddress() {
    return cut(this.address, 5);
  }

  get decimals() {
    return this.networksStore.getNetwork(this.accountsStore.selectedNetwork)?.addressPrefix;
  }

  get address() {
    if (!this.isAddressExists) return '';

    if (this.accountsStore.selectedWallet.address === '') return '';

    const selectedNetwork = this.isGroup ? this.routeParams.selectedNetwork : this.accountsStore.selectedNetwork;

    return BaseApi.formatAddress(this.accountsStore.selectedWallet, selectedNetwork);
  }

  get name() {
    return this.accountsStore.selectedWallet.name;
  }

  get statusConnectedClasses() {
    return !this.extensionStore.tabStatus || !this.extensionStore.tabStatus.isAuthorize
      ? 'fail-connect'
      : 'success-connect';
  }

  get statusConnectedText() {
    return !this.extensionStore.tabStatus || !this.extensionStore.tabStatus.isAuthorize
      ? 'header.notConnected'
      : 'header.connected';
  }

  get isMobile() {
    return !!this.accountsStore.selectedWallet.isMobile;
  }

  copyAddress() {
    setClipboard(this.address);
  }

  toggleSelectNetworkPopupVisible() {
    this.showSelectNetworkPopup = !this.showSelectNetworkPopup;
  }

  @Watch('syncedShowSelectWalletPopup')
  updateZIndexSelectWalletPopup() {
    const targetElement = this.$refs[this.walletNameRef] as HTMLElement;

    targetElement.style.zIndex = this.syncedShowSelectWalletPopup ? '200' : '0';
  }

  @Watch('highlightSettingsIcon')
  updateZIndexShowSettings(value: boolean) {
    const targetElement = (this.$refs[this.settingsNameRef] as Vue).$el as HTMLElement;

    targetElement.style.zIndex = value ? '300' : '0';
  }

  async mounted() {
    this.extensionStore.fetchTabStatus();
  }

  toggleConnectionPopup() {
    if (!this.extensionStore.tabStatus) return;

    this.showConnectionPopup = !this.showConnectionPopup;
  }

  back() {
    this.$router.back();
  }

  openFullScreen() {
    windowOpen('/');
    window.close();
  }

  toggleSettingsVisible() {
    this.$emit('toggleSettingsVisible');
  }

  toggleSelectWalletPopupVisible() {
    this.syncedShowSelectWalletPopup = !this.syncedShowSelectWalletPopup;
  }
}
