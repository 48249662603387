import { BehaviorSubject, Subject } from 'rxjs';
import { addMetadata, knownMetadata } from '@polkadot/extension-chains';
import { isEthereumAddress } from '@polkadot/util-crypto';
import { assert } from '@polkadot/util';
import { accounts } from '@subwallet/ui-keyring/observable/accounts';
import {
  EventService,
  SoraCardService,
  OnboardingService,
  KeyringService,
  StakingService,
  PoolsService,
  NetworkService,
  RequestService,
  WalletConnectService,
  NftService,
  GoogleService,
  WalletConnectDAppService,
  SubscriptionService,
  CronService,
  ScamService,
  PricesService,
  TimeoutService,
  isSubscriptionRunning,
  unsubscribe,
} from '@extension-base/services';
import { api as apiSora, type FPNumber } from '@sora-substrate/util';
import { storage } from '@extension-base/stores/Storage';
import { stripUrl, withErrorLog } from '@extension-base/background/handlers/helpers';
import { fetchEvmAssetBalance } from '@extension-base/api/evm/balance';
import { REFRESH_TIME } from '@extension-base/api/evm/contracts';
import BalanceService from '@extension-base/services/balance-service';
import axios from 'axios';
import { EXTENSION_HOSTNAME, EXTENSION_ID } from '@extension-base/const';
import { NETWORK_STATUS } from '@extension-base/api/types/networks';
import { KeyringLockService } from '@extension-base/services/keyring-service/KeyringLock';
import type { CurrentAccountInfo, CurrentAccountState } from '@extension-base/stores/CurrentAccountStore';
import type {
  ServiceInfo,
  RequestRpcSend,
  RequestRpcSubscribe,
  RequestRpcUnsubscribe,
  ResponseRpcListProviders,
  Port,
  IState,
  ActiveTabAuthorizeStatus,
  Providers,
  FetchEvmBalancePayload,
  AuthUrlInfo,
  AuthUrls,
} from '@extension-base/background/types/types';
import type { ChainRegistry, NetworkJson } from '@extension-base/types';
import type { JsonRpcResponse, ProviderInterface, ProviderInterfaceCallback } from '@polkadot/rpc-provider/types';
import type { MetadataDef, ProviderMeta } from '@polkadot/extension-inject/types';
import type { SoraFees, XcmLocations, XcmFees, NetworkName } from '@/interfaces';
import { isEthereumNetwork } from '@/extension/background/extension-base/src/background/handlers/utils';
import { URLS } from '@/consts/urls';
import { ALL_NETWORKS, FAVORITE_NETWORKS, POPULAR_NETWORKS } from '@/consts/networks';
import { isSameString } from '@/helpers';

export const cacheRegistryMap: Record<string, ChainRegistry> = {};

type Wallet = {
  address: string;
  ethereumAddress: string;
};

export default class State {
  public injectedProviders: Map<Port, ProviderInterface> = new Map();
  public providers: Providers = {};
  public readonly unsubscriptionMap: Record<string, () => void> = {};
  public serviceInfoSubject = new Subject<ServiceInfo>();
  public xcmFees: XcmFees = [];
  public xcmLocations: XcmLocations = [];
  public soraFees: BehaviorSubject<SoraFees> = new BehaviorSubject<SoraFees>(apiSora.NetworkFee);
  public ready = false;
  public currentTabStatus: ActiveTabAuthorizeStatus = {
    isAuthorize: false,
    authorizeAccountsCount: 0,
    dAppName: '',
  };
  public onboardingService = new OnboardingService();
  public eventService = new EventService();
  public keyringService = new KeyringService(this.eventService);
  public networkService = new NetworkService(this.keyringService, this);
  public requestService = new RequestService(this.keyringService, this);
  public walletConnectService = new WalletConnectService(this, this.requestService);
  public walletConnectDappService = new WalletConnectDAppService(this);
  public balanceService = new BalanceService(this);
  public pricesService = new PricesService(this.networkService);
  public nftService = new NftService(this);
  public soraCardService = new SoraCardService(this.requestService);
  public stakingService = new StakingService(this);
  public poolsService = new PoolsService(this);
  public googleService = new GoogleService();
  public cronService = new CronService(this);
  public scamService = new ScamService(this);
  public subscriptionService = new SubscriptionService(this);
  public timeoutService = new TimeoutService(this);
  public keyringLockService = new KeyringLockService(this);

  constructor() {
    this.injectFromStorage();
    this.init();
  }

  public get knownMetadata(): MetadataDef[] {
    return knownMetadata();
  }

  public getEvmApi(key: string) {
    return this.getEvmApiMap[key.toLowerCase()];
  }

  get authSubject() {
    return this.requestService.authSubject;
  }

  public get getSubstrateApiMap() {
    return this.networkService.substrateApiHandler.api;
  }

  public get getEvmApiMap() {
    return this.networkService.evmApiHandler.api;
  }

  public createUnsubscriptionHandle(id: string, unsubscribe: () => void): void {
    this.unsubscriptionMap[id] = unsubscribe;
  }

  public cancelSubscription(id: string): boolean {
    if (isSubscriptionRunning(id)) unsubscribe(id);

    if (this.unsubscriptionMap[id]) {
      this.unsubscriptionMap[id]();

      delete this.unsubscriptionMap[id];
    }

    return true;
  }

  public subscribeServiceInfo() {
    return this.serviceInfoSubject;
  }

  public getFromStorage(key: (keyof IState)[]) {
    return storage.get(key);
  }

  public isReady() {
    return this.ready;
  }

  async injectFromStorage() {
    const { injectedProviders, providers } = await this.getFromStorage(['injectedProviders', 'providers']);

    if (injectedProviders) this.injectedProviders = new Map(injectedProviders);
    if (providers) this.providers = providers;
  }

  async approvePolkaswap(authorizedAccounts: string[]): Promise<void> {
    this.soraCardService.approvePolkaswap(authorizedAccounts);
  }

  public updateCurrentTabsUrl([tab]: chrome.tabs.Tab[]) {
    if (!tab || !tab.url) {
      this.currentTabStatus = {
        isAuthorize: false,
        authorizeAccountsCount: 0,
        dAppName: '',
      };

      return;
    }

    const url = new URL(tab.url);
    const isSelf = url.hostname === EXTENSION_ID || url.hostname === EXTENSION_HOSTNAME;
    const tabHostName = isSelf ? 'header.currentExtensionPage' : url.hostname;

    const cb = () => (authUrls: AuthUrls) => {
      const authorizeUrls = Object.keys(authUrls).filter((url) => url === tabHostName);
      const isAuthorize = authorizeUrls.length !== 0;

      this.currentTabStatus = {
        isAuthorize,
        authorizeAccountsCount: isAuthorize ? authUrls[tabHostName].authorizedAccounts.length : 0,
        dAppName: tabHostName,
      };
    };

    this.requestService.getAuthorize(cb);
  }

  public async onInstall() {
    const currentAccount = this.currentAccount;

    if (currentAccount) {
      this.setCurrentAccount(currentAccount);

      return;
    }

    const accounts = this.keyringService.getSubstrateAccounts();

    if (accounts.length === 0) this.setCurrentAccount(null);
    else {
      const [
        {
          address,
          meta: { name, ethereumAddress, isMobile },
        },
      ] = accounts;

      this.setCurrentAccount({
        address,
        name: name as string,
        ethereumAddress: ethereumAddress as string,
        isMobile: isMobile as boolean,
      });
    }
  }

  async getAuthInfo(url: string, fromList?: AuthUrls): Promise<AuthUrlInfo | undefined> {
    const auths = await this.requestService.getAuthList();
    const authList = fromList || auths;
    const shortenUrl = stripUrl(url);

    return authList[shortenUrl];
  }

  public upsertNetworkMap(data: NetworkJson): boolean {
    return this.networkService.upsertNetworkMap(data, () => this.updateServiceInfo());
  }

  public disableNetworkMap(networkKey: string): boolean {
    this.networkService.disableNetworkMap(networkKey, () => {
      this.updateServiceInfo();

      this.requestService.getAuthorize((data) => this.requestService.setAuthorize(data));
    });

    return true;
  }

  public updateServiceInfo() {
    this.serviceInfoSubject.next({
      networkMap: this.networkService.networkMap,
      apiMap: this.networkService.getApiMap,
      currentAccountInfo: this.currentAccount,
    });
  }

  async setFavoriteNetwork(networkName: string): Promise<boolean> {
    const network = this.networkService.networkMap[networkName];
    const currentAccount = this.currentAccount;

    if (!currentAccount) return false;

    const addressIndex = network.favorite.findIndex((address) => address === currentAccount.address);

    addressIndex !== -1 ? network.favorite.splice(addressIndex, 1) : network.favorite.push(currentAccount.address);

    return true;
  }

  public async setActiveNetworks(type: string) {
    if (!this.currentAccount) return;

    this.networkService.selectedNetworks[this.currentAccount.address] = type;

    const unsub = this.subscriptionService.getSubscription('balance');

    unsub?.();

    const networks = this.networkService.getActiveNetworks();

    Object.entries(this.networkService.networkMap).forEach(([networkName, network]) => {
      const networkKey = networkName.toLowerCase();

      network.active = networks.some(({ name }) => isSameString(name, networkKey));

      const isActive = network.active;

      if (!isActive) {
        if (this.getEvmApiMap[networkKey]) {
          this.getEvmApiMap[networkKey].api?.destroy();

          delete this.getEvmApiMap[networkKey];
        } else if (this.getSubstrateApiMap[networkKey]) {
          this.getSubstrateApiMap[networkKey].provider?.disconnect();

          delete this.getSubstrateApiMap[networkKey];
        }
      }
    });

    if (this.ready) this.networkService.initNetworkApis();

    this.networkService.updateNetworkStore();
    this.networkService.saveSelectedNetworks();

    this.fetchEvmBalance({});
    this.updateServiceInfo();
  }

  getActiveNetworksCurrentWallet(address: string) {
    const uniqNetworks = new Set<NetworkJson>();
    const networks = this.networkService.networkValues;
    const selectedNetwork = this.networkService.selectedNetworks[address];

    if (selectedNetwork === ALL_NETWORKS) return networks;

    if (selectedNetwork === POPULAR_NETWORKS) {
      const popular = networks.filter((el) => el.rank !== undefined);
      popular.forEach((el) => uniqNetworks.add(el));

      return uniqNetworks;
    }

    if (selectedNetwork === FAVORITE_NETWORKS) {
      const favorite = networks.filter((el) => el.favorite.length && el.favorite.includes(address));

      favorite.forEach((el) => uniqNetworks.add(el));

      return uniqNetworks;
    }

    const singleNetwork = networks.find((network) => network.name === selectedNetwork);

    if (singleNetwork) uniqNetworks.add(singleNetwork);

    return uniqNetworks;
  }

  public getAllAddresses(): string[] {
    return Object.keys(accounts.subject.value);
  }

  // List all providers the extension is exposing
  rpcListProviders(): ResponseRpcListProviders {
    return Object.keys(this.providers).reduce((acc, key) => {
      acc[key] = this.providers[key].meta;

      return acc;
    }, {} as ResponseRpcListProviders);
  }

  rpcSend(request: RequestRpcSend, port: Port): Promise<JsonRpcResponse<unknown>> {
    const provider = this.injectedProviders.get(port);

    assert(provider, 'Cannot call pub(rpc.subscribe) before provider is set');

    return provider.send(request.method, request.params);
  }

  // Start a provider, return its meta
  rpcStartProvider(key: string, port: Port): ProviderMeta {
    assert(Object.keys(this.providers).includes(key), `Provider ${key} is not exposed by extension`);

    if (this.injectedProviders.get(port)) {
      return this.providers[key].meta;
    }

    // Instantiate the provider
    this.injectedProviders.set(port, this.providers[key].start());
    storage.set({ injectedProviders: this.injectedProviders });

    // Close provider connection when page is closed
    port.onDisconnect.addListener((): void => {
      const provider = this.injectedProviders.get(port);

      if (provider) {
        withErrorLog(() => provider.disconnect());
      }

      this.injectedProviders.delete(port);

      storage.set({ injectedProviders: this.injectedProviders });
    });

    return this.providers[key].meta;
  }

  rpcSubscribe(
    { method, params, type }: RequestRpcSubscribe,
    cb: ProviderInterfaceCallback,
    port: Port
  ): Promise<number | string> {
    const provider = this.injectedProviders.get(port);

    assert(provider, 'Cannot call pub(rpc.subscribe) before provider is set');

    return provider.subscribe(type, method, params, cb);
  }

  rpcSubscribeConnected(_request: null, cb: ProviderInterfaceCallback, port: Port): void {
    const provider = this.injectedProviders.get(port);

    assert(provider, 'Cannot call pub(rpc.subscribeConnected) before provider is set');

    cb(null, provider.isConnected); // Immediately send back current isConnected

    provider.on('connected', () => cb(null, true));
    provider.on('disconnected', () => cb(null, false));
  }

  rpcUnsubscribe(request: RequestRpcUnsubscribe, port: Port): Promise<boolean> {
    const provider = this.injectedProviders.get(port);

    assert(provider, 'Cannot call pub(rpc.unsubscribe) before provider is set');

    return provider.unsubscribe(request.type, request.method, request.subscriptionId);
  }

  saveMetadata(meta: MetadataDef): void {
    this.requestService.saveMetadata(meta);

    addMetadata(meta);
  }

  public getAccountAddress(): string {
    return this.currentAccount?.address ?? '';
  }

  get currentAccount() {
    return this.keyringService.currentAccount;
  }

  fetchXcmInfo() {
    axios
      .get<XcmLocations>(URLS.XCM_LOCATIONS)
      .then(({ data }) => (this.xcmLocations = data))
      .catch(() => (this.xcmLocations = []));

    axios
      .get<XcmFees>(URLS.XCM_FEES)
      .then(({ data }) => (this.xcmFees = data))
      .catch(() => (this.xcmFees = []));
  }

  public async init() {
    await this.eventService.waitCryptoReady;
    await this.networkService.initNetworkMap();

    this.keyringService
      .getSubstrateAccounts()
      .forEach(({ address }) => this.balanceService.generateDefaultBalance(address));

    this.ready = true; // Set true if chain json is parsed and data is preped for init apis
    this.fetchXcmInfo();
    this.scamService.refreshScamAddressList();

    this.networkService.initNetworkApis();
    this.onReady();
    this.updateServiceInfo();
  }

  public updateNetworkForNewWallet(address: string) {
    this.setActiveNetworks(this.networkService.selectedNetworks[address] ?? ALL_NETWORKS);
  }

  public updateCurrentAccount(address: string, isNew = true): boolean {
    if (isEthereumAddress(address)) return false;

    this.balanceService.generateDefaultBalance(address);

    this.saveCurrentAccountAddress(address, () => {
      this.keyringService.triggerWalletsSubscription();

      if (isNew) this.setActiveNetworks(this.networkService.selectedNetworks[address] ?? ALL_NETWORKS);

      this.nftService.publishNfts();
    });

    return true;
  }

  public setCurrentAccount(data: CurrentAccountState, callback: () => void = () => null): void {
    this.keyringService.setCurrentAccount(data);

    // logic for Sora library
    if (data?.address && !data.isMobile) {
      this.poolsService.unsubscribePools();

      const pair = this.keyringService.getPair(data?.address)!;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      apiSora.account = { json: null as any, pair };

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      apiSora.bridgeProxy.sub.account = { json: null as any, pair };

      // TODO добавить фича тогл
      // this.subscribeTotalXorBalance();
    }

    this.updateServiceInfo();
    callback();
  }

  public saveCurrentAccountAddress(address: string, callback?: (account: CurrentAccountState) => void) {
    if (address === '') return this.setCurrentAccount(null);

    const {
      meta: { isMobile, name, ethereumAddress },
    } = this.keyringService.getAccount(address) ?? this.keyringService.getAddress(address)!;

    const accountInfo: CurrentAccountInfo = {
      address,
      isMobile: !!(isMobile as boolean),
      name: name as string,
      ethereumAddress: (ethereumAddress as string) ?? '',
    };

    this.setCurrentAccount(accountInfo, () => callback?.(accountInfo));
  }

  cleanupDeletedAccount(address: string) {
    if (this.networkService.selectedNetworks[address]) {
      delete this.networkService.selectedNetworks[address];

      storage.set({ selectedNetworks: this.networkService.selectedNetworks });
    }

    this.nftService.deleteSavedNfts(address);
    this.balanceService.deleteBalance(address);
  }

  public subscribeTotalXorBalance() {
    if (!apiSora.api || !apiSora.api.isConnected) return;

    try {
      const subscription = apiSora.assets
        .getTotalXorBalanceObservable()
        .subscribe((xorTotalBalance: FPNumber) => this.balanceService.updateXorTotalBalance(xorTotalBalance));

      this.subscriptionService.updateSubscription({ name: 'xorTotalBalance', func: subscription.unsubscribe });
    } catch (ex) {
      console.error('failed subscribe or unsubscribe to XOR balance');
    }
  }

  public getAddressList(value = false): Record<string, boolean> {
    const addressList = Object.keys(accounts.subject.value);

    return addressList.reduce((addressList, v) => ({ ...addressList, [v]: value }), {});
  }

  private onReady() {
    this.subscriptionService.start();
    this.cronService.start();

    this.ready = true;
  }

  getCurrentAddress(network: NetworkName, _currentAccount?: CurrentAccountState): string {
    const currentAccount = _currentAccount ?? this.currentAccount;

    return isEthereumNetwork(network) ? currentAccount!.ethereumAddress : currentAccount!.address;
  }

  async fetchEvmBalance({ _networks, ethereumAddress: _ethereumAddress, assetId, force }: FetchEvmBalancePayload) {
    if (!this.ready) return;

    const ethereumAddress = _ethereumAddress ?? this.currentAccount?.ethereumAddress ?? '';

    if (ethereumAddress === '') return;

    const activeEvmNetworks = this.networkService.evmNativeNetworkValues.filter(({ name, active }) => {
      if (_networks && !_networks.includes(name)) return false;

      return active;
    });

    if (!activeEvmNetworks.length) return;

    activeEvmNetworks.forEach(({ assets, name, networkStatus }) => {
      const api = this.getEvmApi(name);

      const timeout = api.timeout[ethereumAddress] ?? Number.MIN_VALUE;
      const timeDiff = Date.now() - timeout;
      const shouldSkipUpdate = timeDiff < REFRESH_TIME && !force;

      if (shouldSkipUpdate || networkStatus === NETWORK_STATUS.DISCONNECTED) return;

      // Save timeout [network api][ethereum address]
      if (api) api.timeout[ethereumAddress] = Date.now();

      if (assetId) fetchEvmAssetBalance(ethereumAddress, name, assetId, this);
      else assets.forEach(({ id }) => fetchEvmAssetBalance(ethereumAddress, name, id, this));
    });
  }

  formatAddress({ address, ethereumAddress }: Wallet, networkName: string = 'westend'): string {
    const isEthereumNet = isEthereumNetwork(networkName);

    if (isEthereumNet) return ethereumAddress;

    const network = this.networkService.networksGithub.find(
      ({ name }) => name.toLowerCase() === networkName.toLowerCase()
    )!;

    // the only case for try/catch
    // if the user used ethereum account instead of a substratum account(via json or private key)
    try {
      return this.keyringService.encodeAddress(address, network?.addressPrefix);
    } catch {
      return ethereumAddress;
    }
  }

  isSameAddress(wallet1: Wallet, wallet2: Wallet): boolean {
    return this.formatAddress(wallet1) === this.formatAddress(wallet2);
  }

  public async switchEvmNetworkByUrl(shortenUrl: string, networkKey: string): Promise<void> {
    const authUrls = await this.requestService.getAuthList();
    const network = this.networkService.getNetworkJson(networkKey);

    if (authUrls[shortenUrl]) {
      if (!network.active) await this.setActiveNetworks(networkKey);

      authUrls[shortenUrl].currentEvmNetworkKey = networkKey;

      this.requestService.setAuthorize(authUrls);
    } else {
      throw new Error(`Not found ${shortenUrl} in auth list`);
    }
  }
}
