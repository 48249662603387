
import { Component, Vue, Prop } from 'vue-property-decorator';
import type { BuyProvider } from '@/interfaces';
import { getProviderUrl } from '@/helpers/currencies';
import { useExtensionStore } from '@/stores/extension';

@Component
export default class BuyPopup extends Vue {
  extensionStore = useExtensionStore();

  @Prop(String) asset!: string;
  @Prop(String) address!: string;
  @Prop(Array) providers!: ('ramp' | 'moonpay')[];

  get headerText() {
    return this.$t('assets.buyHeader', { asset: this.asset });
  }

  get providersFiltered() {
    return this.providers.filter((provider) => this.extensionStore.features?.fiat[provider]);
  }

  openProvider(providerName: BuyProvider) {
    const url = getProviderUrl(providerName, this.asset, this.address);

    window.open(url);
  }
}
