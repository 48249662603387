import { render, staticRenderFns } from "./Switcher.vue?vue&type=template&id=ccc2597a&"
import script from "./Switcher.vue?vue&type=script&lang=ts&setup=true&"
export * from "./Switcher.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./Switcher.vue?vue&type=style&index=0&id=ccc2597a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports