
import { Component, Vue } from 'vue-property-decorator';
import { Components } from '@/router/routes';
import { URLS } from '@/consts/urls';
import { hasMasterPassword, initGoogleAuth } from '@/extension/messaging';
import { useAccountsStore } from '@/stores/accounts';
import { IS_EXTENSION } from '@/consts/global';

@Component
export default class Welcome extends Vue {
  readonly isExtension = IS_EXTENSION;
  accountsStore = useAccountsStore();
  showGoogleAuthPopup = false;
  isAuthFlowInit = false;
  hasMasterPassword = false;

  get showBackWalletIcon() {
    return this.accountsStore.accounts.length !== 0;
  }

  get accessToken() {
    return this.$route.params.access_token;
  }

  async created() {
    this.hasMasterPassword = await hasMasterPassword();

    if (this.accessToken) this.showGoogleAuthPopup = true;
  }

  closeGooglePopup() {
    this.showGoogleAuthPopup = false;
  }

  openTermsAndConditions() {
    window.open(URLS.FEARLESS_TERMS);
  }

  openPrivacyPolicy() {
    window.open(URLS.FEARLESS_PRIVACY);
  }

  backToWallet() {
    this.$router.push({ name: Components.Wallet });
  }

  async manageGoogle() {
    if (!this.hasMasterPassword) {
      this.$router.push({ name: Components.ChangePassword, params: { name: 'GoogleAuth' } });

      return;
    }

    if (!this.isAuthFlowInit) {
      this.isAuthFlowInit = true;

      initGoogleAuth().finally(() => (this.isAuthFlowInit = false));
    }
  }

  async openAddWalletComponent(type: string) {
    if (this.hasMasterPassword) this.$router.push({ name: Components.AddWallet, params: { type } });
    else this.$router.push({ name: Components.ChangePassword, params: { name: 'AddWallet', type } });
  }

  async openAddWalletMobile() {
    const path = this.hasMasterPassword
      ? { name: Components.MobileWalletAuth }
      : { name: Components.ChangePassword, params: { name: 'MobileWalletAuth' } };

    this.$router.push(path);
  }
}
