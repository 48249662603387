
import { Vue, Component, Prop } from 'vue-property-decorator';
import { storage } from '@extension-base/stores/Storage';

import BaseApi from '@/util/BaseApi';
@Component
export default class EditAddressBook extends Vue {
  name = '';
  address = '';
  saveForAllNetworks = false;

  @Prop({ default: '' }) _name!: string;
  @Prop(String) _address!: string;
  @Prop(String) network!: string;
  @Prop(Boolean) isActive!: boolean;

  get buttonDisabled() {
    return this.name === '' || this.address.trim() === '' || this.isErrorAddress;
  }

  get isErrorAddress() {
    const address = this.address.trim();

    return (
      address.length !== 0 &&
      !(BaseApi.validateAddress(address, 'polkadot') || BaseApi.validateAddress(address, 'moonbeam'))
    );
  }

  changeName(value: string) {
    this.name = value;
  }

  changeAddress(value: string) {
    this.address = value;
  }

  mounted() {
    this.name = this._name;
    this.address = this._address;
  }

  async updateContact() {
    const { addressBook } = await storage.get(['addressBook']);
    const key = this.saveForAllNetworks ? 'all' : this.network;
    const value = addressBook[key] ?? [];

    storage.set({
      addressBook: {
        ...addressBook,
        [key]: [...value, { name: this.name, address: BaseApi.encodeAddress(this.address.trim()) }],
      },
    });

    this.$emit('toggleEditBook');
  }

  onSave(value: boolean) {
    this.saveForAllNetworks = value;
  }
}
